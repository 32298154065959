import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 535.000000 424.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)">
<path d="M4915 2133 c-494 -18 -1171 -116 -1630 -238 -930 -245 -1786 -643
-2453 -1139 -212 -157 -482 -391 -482 -417 0 -5 272 -9 609 -9 l610 0 88 102
c241 281 598 599 933 830 226 156 561 345 785 443 22 10 58 26 80 35 317 140
757 272 1081 325 188 31 242 31 79 0 -345 -64 -764 -192 -1028 -314 -37 -17
-70 -31 -73 -31 -3 0 -82 -39 -177 -86 -390 -196 -756 -443 -1062 -719 -162
-146 -379 -369 -456 -470 -13 -16 -26 -32 -29 -35 -14 -13 -50 -64 -50 -71 0
-5 257 -8 587 -7 l587 3 79 130 c418 687 934 1205 1462 1467 176 87 357 151
532 188 90 19 89 19 -72 13z"/>
<path d="M4670 1946 c-407 -164 -768 -437 -1134 -857 -63 -73 -77 -103 -57
-125 8 -8 160 -270 304 -524 33 -58 65 -108 71 -112 15 -10 614 -10 619 -1 3
5 -38 82 -90 173 -109 188 -263 455 -336 580 -27 47 -71 125 -99 173 l-50 89
33 35 c127 133 263 250 409 352 144 102 191 128 410 233 85 41 37 31 -80 -16z"/>
<path d="M0 23 c0 -13 5 -23 10 -23 13 0 13 11 0 30 -8 12 -10 11 -10 -7z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
